import React from 'react'
import { Helmet } from 'react-helmet'
import { Fade } from 'react-reveal';
import Layout from '../../components/layout'

const VertiStacks = props => (
    <Layout>
        <Helmet>
            <title>VertiStacks</title>
            <meta name="description" content="Vertistacks Info" />
        </Helmet>
        <Fade duration={2500}>
            <div id="main" className="wrapper style1">
                <div className="container">
                <header className="major">
                    <h2>VertiStacks</h2>
                    <p>
                    SGP Foods’ VertiStacks is a solution that enables vertical farming of food crops via IoT technology.
                    </p>
                </header>
                <div className="row gtr-150">
                    <div style={{textAlign:`center`, fontSize:`1.15em`}}><b>The VertiStacks comprises of a system that is equipped with physical cultivation apparatuses embedded with digital toolsets that assists with the cultivation process. 
These digital toolsets, enabled by IoT technology can make customizable adjustments to the cultivation process. </b>
                    </div>
                    <br/>
                    <div style={{textAlign:`center`, fontSize:`1.15em`}}><b>
                    Input factors such as light, temperature, humidity, moisture, and soil pH can be monitored and adjusted based on the plant’s status from a single interface. The plant status can be measured and monitored where measurable outputs such as plant height, leaf size, rate of growth, etc. are available. 
These features of VertiStacks work towards a common goal of maintaining crop health and a successful harvest. Furthermore, they also allow VertiStacks to economize land, water, soil nutrients and labour through the cultivation process, increasing cost-savings and yield for our users.
</b>
                    </div>
                    <br/><b>
                    <div style={{textAlign:`center`, fontSize:`1.15em`}}>We strongly believe that VertiStacks offers state-of-the-art solutions for vertical farming whilst offering more cost-effective and quality improvements over conventional food crop production methods.</div>
                    </b>
                </div>
                
                </div>
            </div>
        </Fade>
    </Layout>
)

export default VertiStacks